var Routes = function() {
    return this.define;
};

/*
 * Route definitions
 */
Routes.prototype.define = {
    home: crossroads.addRoute("/"),
    onthecourt: crossroads.addRoute("onthecourt/:page::?query:"),
    offthecourt: crossroads.addRoute("offthecourt/:page::?query:"),
}


var routes = new Routes();

var page_title = "TYUS JONES △ Write Your Own Story";



/*
 * Home
 */
routes.home.matched.add(function() {
    pages.change("home");
    document.title = page_title;
}.bind(routes));

/*
 * On the court
 */
routes.onthecourt.matched.add(function(page) {
    pages.change("onthecourt", page);
    document.title = "On the Court - " + page_title;
}.bind(routes));

/*
 * Off the court
 */
routes.offthecourt.matched.add(function(page) {
    //console.log(page);
    pages.change("offthecourt", page);
    document.title = "Off the Court - " + page_title;
}.bind(routes));



var Navigator;

(function(window, undefined) {

    Navigator = History;
   
    // Watch for statechange
    Navigator.Adapter.bind(window, 'statechange', function() { // Note: We are using statechange instead of popstate
        return crossroads.parse(document.location.pathname + document.location.search);
    });

})(window);
