

var debug = false;
var mobile = false;
var video = true;
var ambientVideo = true;
var parallax = true;
var gradient = true;
var scrolling = false;
var transition = true;

// Console Styles
var console_default = "font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; background-color: #ffffff; font-size:14px; padding:5px 0; ";
var console_app = console_default + "color: blue; background-color: #ffffff; font-size:14px;";
//var console_googlemaps = console_default + "color: orange; background-color: #ffffff; font-size:14px;";
var console_googlemaps = console_default + "color: purple; background-color: #ffffff; font-size:14px;";
//var console_page = console_default + "color: green; background-color: #ffffff; font-size:14px;";
var console_threejs  = console_default + "color: red; background-color: #ffffff; font-size:14px;";
var console_ambient_video = console_default + "color: magenta; background-color: #ffffff; font-size:14px;";
var console_scroll_magic = console_default + "color: brown; background-color: #ffffff; font-size:14px;";
var console_test = console_default + "color: white; background-color: red; font-size:20px; padding:5px;";

CSSPlugin.defaultTransformPerspective = 500;


/**
 * Client web browser detection and site settings based on results.
 */

var browser_detect = {
	init: function() {
		this.browser = this.searchString(this.dataBrowser) || "Other";
		this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";

		browser_detect.settings();

		if (debug) {
			console.log("%cBrowser Detect || Browser: " + this.browser + ", Version: " + this.version, console_default);
		}
	},
	searchString: function(data) {
		var len = data.length;
		for (var i = 0; i < len; ++i) {
			var dataString = data[i].string;
			this.versionSearchString = data[i].subString;

			if (dataString.indexOf(data[i].subString) !== -1) {
				return data[i].identity;
			}
		}
	},
	searchVersion: function(dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index === -1) {
			return;
		}
		
		var rv = dataString.indexOf("rv:");
		if (this.versionSearchString === "Trident" && rv !== -1) {
			return parseFloat(dataString.substring(rv + 3));
		} else {
			return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
		}
	},

	dataBrowser: [{
		string: navigator.userAgent,
		subString: "Chrome",
		identity: "Chrome"
	}, {
		string: navigator.userAgent,
		subString: "MSIE",
		identity: "Explorer"
	}, {
		string: navigator.userAgent,
		subString: "Trident",
		identity: "Explorer"
	}, {
		string: navigator.userAgent,
		subString: "Firefox",
		identity: "Firefox"
	}, {
		string: navigator.userAgent,
		subString: "Safari",
		identity: "Safari"
	}, {
		string: navigator.userAgent,
		subString: "Opera",
		identity: "Opera"
	}],

	settings: function() {
		//http://stackoverflow.com/questions/25455432/testing-for-svg-mask-support-in-js
		//console.log(document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Mask", "1.0"))
		if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
			mobile = true;

			ambientVideo = false;
		}

		if(Modernizr.touch) {
			touch = true;
		}

		if (!Modernizr.svg || !Modernizr.inlinesvg || Modernizr.svgasimg) {
			svg_active = false;
		}

		if (browser_detect.browser == "Firefox" || browser_detect.browser == "Explorer") {
			
		}

		if (browser_detect.browser == "Firefox") {

		}

		if (browser_detect.browser == "Explorer" && browser_detect.version <= "8") {
			alert("Your browser is outdated. Please upgrade to view this website.");
		}

		if (browser_detect.browser == "Explorer" && browser_detect.version <= 9) {

		}

		if (debug) {
			console.log("%cBrowser Detect // Settings", console_default);
		}

		if(mobile) {
			parallax = false;
		}

		if (mobile) {
			$("body").addClass("mobile");
		}

		if (mobile && browser_detect.browser == "Safari") {
			$("body").addClass("ios");

			function iPhoneVersion() {
				var iHeight = window.screen.height;
				var iWidth = window.screen.width;
				if (iWidth === 320 && iHeight === 480) {
					return "4";
				}
				else if (iWidth === 375 && iHeight === 667) {
					return "6";
				}
				else if (iWidth === 414 && iHeight === 736) {
					return "6+";
				}
				else if (iWidth === 320 && iHeight === 568) {
					return "5";
				}
				else if (iHeight <= 480) {
					return "2-3";
				}
				return 'none';
			}

			function isIphone() {
				return !!navigator.userAgent.match(/iPhone/i);
			}

			if (isIphone()) {
				//alert(iPhoneVersion());
			}

			if (isIphone() && (iPhoneVersion() === "5" || iPhoneVersion() === "4" || iPhoneVersion() === "2-3")) {
				transition = false;
			}
		}
	}
	
};


/**
 * General App Controller
 */

var app = {
	page: '',
	firstsize: true,
	orientationchanged: false,
	delayedloaded: false,
	window_height: 0,
	orientation: "landscape",
	init: function() {

		if (debug) {
			console.log("%cApp // Init", console_app);
		}

		setTimeout(function(){ $("body").addClass("loaded"); }, 500);

		var screen_height = window.screen.height;
		var screen_width = window.screen.width;
		var orientation = "landscape";
		var largest_dimension = window.screen.width;

		if(screen_height > screen_width) {
			orientation = "portrait";
			largest_dimension = window.screen.height;
		}

		browser_detect.init();
		ambient_video.init();
		scroll_animation.init();

		viewport;

		this.update();
		this.size_content();
		this.categorize_links();
		this.mouse_events();

		imageGallery();

		if(document.location.pathname) {
			pages.notransition = true;
			crossroads.parse(document.location.pathname + document.location.search);
		} else {
			Navigator.pushState(null, null, '/');
		}
	},
	update: function() {
		if (debug) {
			console.log("%cApp // Update", console_app);
		}

		app.window_width = $(window).width();
		app.window_height = $(window).height();

		this.size_content();
	},
	size_content: function() {
		if (debug) {
			console.log("%cApp // Size Content", console_app);
		}

		pages.resize();

		function viewportHeight() {
			return window.innerHeight == null ? $(window).height() : window.innerHeight;
		}

	},
	clear_sizing: function() {
		if (debug) {
			console.log("%cApp // Clear Sizing", console_app);
		}

		app.orientationchanged = true;
	},
	categorize_links: function() {
		$('a').each(function() {
			if (($(this).attr('href')) != "#") {
				if (this.host !== location.host) {
					$(this).addClass('external');
				} else if($(this).attr('href').indexOf('.') !== -1) {
					$(this).addClass('external');
				} else {
					$(this).addClass('local');
				}
			}
		});

		if (debug) {
			console.log("%cApp // Categorize Links", console_app);
		}
	},
	mouse_events: function() {

		if (debug) {
			console.log("%cApp // Mouse Events", console_app);
		}

		var page = "home";


		$(document).on("click", "a", function(e) {
			if($(this).hasClass("local")) {
				e.preventDefault();
				var clicked_link = $(this).attr('href');
				Navigator.pushState(null, null, clicked_link);
			}
		});

		$(".hovers > div").on("click", function(e) {
			var clicked_link = $(this).data('url');
			Navigator.pushState(null, null, clicked_link);
		});

		$(".scroll_container").on("click", function(e) {
			TweenMax.to($(".pages > ." + pages.current + " .overflow_container"), 1.25, {scrollTo: $(window).height(), ease: Power3.easeOut});
		});

		$(".back_to_top").on("click", function(e) {
			TweenMax.to($(".pages > ." + pages.current + " .overflow_container"), 1.25, {scrollTo: 0, ease: Power3.easeOut});
		});

		$(".home .intro").swipe({
			swipeRight:function(event, direction, distance, duration, fingerCount) {
				Navigator.pushState(null, null, "/onthecourt");
			},
			swipeLeft:function(event, direction, distance, duration, fingerCount) {
				Navigator.pushState(null, null, "/offthecourt");
			}
		});

		$(".offthecourt .intro").swipe({
			swipeRight:function(event, direction, distance, duration, fingerCount) {
				Navigator.pushState(null, null, "/");
			}
		});

		$(".onthecourt .intro").swipe({
			swipeLeft:function(event, direction, distance, duration, fingerCount) {
				Navigator.pushState(null, null, "/");
			}
		});

		$('#onthecourt .overflow_container').on('scroll', function () {
			if ($('#onthecourt .overflow_container').scrollTop() > (app.window_height * 1.1)) {
				TweenMax.set($('.background .cover'), {
					autoAlpha: 1
				});
			} else {
				TweenMax.set($('.background .cover'), {
					autoAlpha: 0
				});
			}
		});

		$('#offthecourt .overflow_container').on('scroll', function () {
			if ($('#offthecourt .overflow_container').scrollTop() > (app.window_height * 1.1)) {
				TweenMax.set($('.background .cover'), {
					autoAlpha: 1
				});
			} else {
				TweenMax.set($('.background .cover'), {
					autoAlpha: 0
				});
			}
		});

		$(".menu_button").on("click", function(e) {
			//TweenMax.to($(".menu"), 1.25, {y: 0, ease: Power3.easeOut});
			$("body").addClass("menu_open");
		});

		$(".menu a").on("click", function(e) {
			$("body").removeClass("menu_open");
		});


		var transitionTiming = .25;

		$(".close_button").on("click", function(e) {
			if($("body").hasClass("menu_open")) {
				$("body").removeClass("menu_open");
			} else if($("body").hasClass("sub_page_active")) {
				Navigator.pushState(null, null, "/" + pages.current);
			}
			
		});

	},
	scroll_to: function(id) {
		scrolling = true;

		var offset = $("#" + id).position();
		var scrollTop = $(".pages > ." + pages.current + " .overflow_container").scrollTop();
		var scrollAmount = offset.top + scrollTop;
		var scroll_time = scrollAmount / 2500;

		function cleanup() {
			scrolling = false;
		}

		TweenMax.to($(".pages > ." + pages.current + " .overflow_container"), scroll_time, {
			scrollTo : { y: scrollAmount, autoKill:true },
				ease: Power1.easeInOut,
				overwrite: 5,
				delay: .75,
				onComplete: cleanup
		});
	}
};




var pages = {
	current: "",
	currentSub: "",
	previous: "",
	notransition: false,
	init: function() {
		
	},
	change: function(newPage, subPage) {

		if(pages.current != newPage) {
			var timing = .85;

			if(!transition) {
				pages.notransition = true;
			}

			if(pages.notransition == true) {
				timing = .00001;
				pages.notransition = false;
			} else {
				TweenMax.set($('.background .cover'), {
					autoAlpha: 0
				});
			}

			pages.previous = pages.current;

			if(newPage == "home") {
				$("body").addClass("home").removeClass("offthecourt").removeClass("onthecourt");

				TweenMax.to(".pages", timing, {x: 0, ease: Power3.easeOut});

				TweenMax.to(".tyus_logo_link", timing*1.5, {x: -150, autoAlpha: 0, ease: Power3.easeOut});

				TweenMax.to(".background .left", timing, {x: "-50%", ease: Power3.easeOut});
				TweenMax.to(".background .right", timing, {x: "50%", ease: Power3.easeOut});

				if (viewport.is('xs') || viewport.is('sm')) {
					TweenMax.to(".background .left .photo", timing, {x: '-23%', ease: Power3.easeOut});
					TweenMax.to(".background .right .photo", timing, {x: '-27%', ease: Power3.easeOut});
				} else {
					TweenMax.to(".background .left .photo", timing, {x: '25%', ease: Power3.easeOut});
					TweenMax.to(".background .right .photo", timing, {x: '-25%', ease: Power3.easeOut});
				}

				TweenMax.to(".scroll", timing*.8, {y: 0, autoAlpha: 0, ease: Power3.easeOut, onComplete: pages.complete});

				pages.current = "home";
			} else if(newPage == "offthecourt") {
				$("body").addClass("offthecourt").removeClass("onthecourt").removeClass("home");

				TweenMax.to(".pages", timing*1.5, {x: -$( window ).width(), ease: Power3.easeOut});

				TweenMax.to(".tyus_logo_link", timing*1.5, {x: 0, autoAlpha: 1, ease: Power3.easeOut});

				TweenMax.to(".background .left", timing*1.5, {x: "-85%", ease: Power3.easeOut});
				TweenMax.to(".background .right", timing*1.5, {x: "0%", ease: Power3.easeOut});

				if (viewport.is('xs') || viewport.is('sm')) {
					TweenMax.to(".background .right .photo", timing*1.5, {x: '-25%', ease: Power3.easeOut});
				} else {
					TweenMax.to(".background .right .photo", timing*1.5, {x: '0%', ease: Power3.easeOut});
					TweenMax.to(".background .left .photo", timing*1.5, {x: '35%', ease: Power3.easeOut});
				}

				TweenMax.to(".scroll", timing*1.1, {y: 0, autoAlpha: 1, ease: Power3.easeOut, onComplete: pages.complete});

				pages.current = "offthecourt";
			} else if(newPage == "onthecourt") {
				$("body").addClass("onthecourt").removeClass("offthecourt").removeClass("home");

				TweenMax.to(".pages", timing*1.5, {x: $( window ).width(), ease: Power3.easeOut});

				TweenMax.to(".tyus_logo_link", timing*1.5, {x: 0, autoAlpha: 1, ease: Power3.easeOut});

				TweenMax.to(".background .left", timing*1.5, {x: "0%", ease: Power3.easeOut});
				TweenMax.to(".background .right", timing*1.5, {x: "85%", ease: Power3.easeOut});

				if (viewport.is('xs') || viewport.is('sm')) {
					TweenMax.to(".background .left .photo", timing*1.5, {x: '-25%', ease: Power3.easeOut});
				} else {
					TweenMax.to(".background .left .photo", timing*1.5, {x: '0%', ease: Power3.easeOut});
					TweenMax.to(".background .right .photo", timing*1.5, {x: '-35%', ease: Power3.easeOut});
				}

				TweenMax.to(".scroll", timing*1.1, {y: 0, autoAlpha: 1, ease: Power3.easeOut, onComplete: pages.complete});

				pages.current = "onthecourt";
			}
		}

		if($(".sub_pages #" + subPage).length) {
			if(pages.currentSub != subPage) {
				if(subPage == "" || typeof subPage == 'undefined') {

					if(pages.currentSub != "") {
						pages.subClose();
					}
				} else {
					pages.subChange(subPage);
				}
			}
		} else if($(".pages #" + subPage).length) {
			app.scroll_to(subPage);

			if(pages.currentSub != "") {
				if($(".sub_pages #" + pages.currentSub).length) {
					pages.subClose();
				}
			}
		}

		// If a sub page is open and a new subPage doesn't exist then close the overlay.
		if(pages.currentSub != "" && typeof subPage == 'undefined') {
			pages.subClose();
		}
	},

	complete: function() {
		if(pages.previous == "onthecourt" || pages.previous == "offthecourt") {
			$(".pages > ." + pages.previous + " .overflow_container").scrollTop(0);
			$(".page.onthecourt").removeClass("overflowscroll");
			$(".page.offthecourt").removeClass("overflowscroll");
		}

		if(pages.current == "onthecourt" || pages.current == "offthecourt") {
			$(".pages > ." + pages.current).addClass("overflowscroll");
		}
	},
	subChange: function(newPage) {

		$("#" + newPage).show();

		var transitionTiming = .35;

		$("body").addClass("sub_page_active");
		TweenMax.fromTo(".sub_pages_container", transitionTiming, {z: 100,}, {z: 0, display: 'block', ease: Power1.easeOut, force3D:true, delay: transitionTiming});
		TweenMax.to("main", transitionTiming, {z: -150, ease: Power1.easeIn, force3D:true});

		TweenMax.to(".page_cover", transitionTiming, {autoAlpha: 1, display: 'block', ease: Power1.easeOut, force3D:true});
		TweenMax.to(".page_cover", transitionTiming, {autoAlpha: 0, display: 'none', ease: Power1.easeOut, force3D:true, delay: transitionTiming});

		pages.currentSub = newPage;
		
		scroll_animation.pause();
	},
	subClose: function() {

		var transitionTiming = .35;

		function cleanup() {
			$(".sub_page").hide();
			pages.currentSub = "";
			scroll_animation.resume();
		}

		$("body").removeClass("sub_page_active");
		
		TweenMax.fromTo(".sub_pages_container", transitionTiming, {z: 0,}, {z: 100, display: 'none', ease: Power1.easeIn, force3D:true});
		TweenMax.to("main", transitionTiming, {z: 0, ease: Power1.easeOut, force3D:true, delay: transitionTiming});
		
		TweenMax.to(".page_cover", transitionTiming, {autoAlpha: 1, display: 'block', ease: Power1.easeOut, force3D:true});
		TweenMax.to(".page_cover", transitionTiming, {autoAlpha: 0, display: 'none', ease: Power1.easeOut, force3D:true, delay: transitionTiming, onComplete: cleanup});
		
	},
	resize: function() {
		if(pages.current == "home") {
			TweenMax.set(".pages", {x: 0});

			TweenMax.set(".background .left", {x: "-50%", ease: Power3.easeOut});
			TweenMax.set(".background .right", {x: "50%", ease: Power3.easeOut});

			if (viewport.is('xs') || viewport.is('sm')) {
				TweenMax.set(".background .left .photo", {x: '-23%', ease: Power3.easeOut});
				TweenMax.set(".background .right .photo", {x: '-27%', ease: Power3.easeOut});
			} else {
				TweenMax.set(".background .left .photo", {x: '25%', ease: Power3.easeOut});
				TweenMax.set(".background .right .photo", {x: '-25%', ease: Power3.easeOut});
			}

		} else if(pages.current == "offthecourt") {
			TweenMax.set(".pages", {x: -$( window ).width(), ease: Power3.easeOut});

			TweenMax.set(".tyus_logo_link", {x: 0, autoAlpha: 1, ease: Power3.easeOut});

			TweenMax.set(".background .left", {x: "-75%", ease: Power3.easeOut});
			TweenMax.set(".background .right", {x: "0%", ease: Power3.easeOut});

			if (viewport.is('xs') || viewport.is('sm')) {
				TweenMax.set(".background .right .photo", {x: '-25%', ease: Power3.easeOut});
			} else {
				TweenMax.set(".background .right .photo", {x: '0%', ease: Power3.easeOut});
				TweenMax.set(".background .left .photo", {x: '35%', ease: Power3.easeOut});
			}

			TweenMax.set(".scroll", {y: 0, autoAlpha: 1, ease: Power3.easeOut});
		} else if(pages.current == "onthecourt") {
			TweenMax.set(".pages", {x: $( window ).width(), ease: Power3.easeOut});

			TweenMax.set(".tyus_logo_link", {x: 0, autoAlpha: 1, ease: Power3.easeOut});

			TweenMax.set(".background .left", {x: "0%", ease: Power3.easeOut});
			TweenMax.set(".background .right", {x: "75%", ease: Power3.easeOut});

			if (viewport.is('xs') || viewport.is('sm')) {
				TweenMax.set(".background .left .photo", {x: '-25%', ease: Power3.easeOut});
			} else {
				TweenMax.set(".background .left .photo", {x: '0%', ease: Power3.easeOut});
				TweenMax.set(".background .right .photo", {x: '-35%', ease: Power3.easeOut});
			}

			TweenMax.set(".scroll", {y: 0, autoAlpha: 1, ease: Power3.easeOut, onComplete: pages.complete});
		}
	}
};


var ambient_video = {
	to_load: 0,
	playing: false,
	faded: false,
	canplay: 0,
	init: function() {
		
		if (ambientVideo) {
			
			if (debug) {
				console.log("%cAmbient Video // Init", console_ambient_video);
			}
			
			$("#on_the_court_video").mediaelementplayer({
				pauseOtherPlayers: false,
				enableKeyboard: false,
				success: function(media, node, instance) {
					if (debug) {
						console.log("%cAmbient Video // Success", console_ambient_video);
					}

					media.load();

					media.addEventListener('canplay', function() {
						ambient_video.canplay++;
					}, false);
				},
				error: function (e) { 
					if (debug) {
						console.log("%cAmbient Video // Error", console_ambient_video);
					}
				}
			});

			$("#off_the_court_video").mediaelementplayer({
				pauseOtherPlayers: false,
				enableKeyboard: false,
				success: function(media, node, instance) {
					if (debug) {
						console.log("%cAmbient Video // Success", console_ambient_video);
					}

					media.load();

					media.addEventListener('canplay', function() {
						ambient_video.canplay++;
					}, false);
				},
				error: function (e) { 
					if (debug) {
						console.log("%cAmbient Video // Error", console_ambient_video);
					}
				}
			});


			function checkIfReady() {
				setTimeout(function () {
					if(ambient_video.canplay>=2) {
						ambient_video.play();
						ambient_video.fade_in();
					} else {
						checkIfReady()
					}
				}, 25);
			}

			checkIfReady();

		} else {
			if (debug) {
				console.log("%cAmbient Video // Init - Disabled", console_ambient_video);
			}

			$(".fluid_video video").css("display", "none");

			$(".background .photo").css("display", "block");

			TweenMax.to($('.background .cover'), 3, {
				autoAlpha: 0,
				ease: Power3.easeOut,
				delay: 0
			});

			$("body").addClass("novideo");
		}


		$("#learninglab_video").mediaelementplayer({
			features: ['playpause','progress','current','duration','tracks','volume','fullscreen'],
			pauseOtherPlayers: false,
			enableKeyboard: false,
			success: function(media, node, instance) {
				if (debug) {
					console.log("%cAmbient Video // Success", console_ambient_video);
				}
			},
			error: function (e) { 
				if (debug) {
					console.log("%cAmbient Video // Error", console_ambient_video);
				}
			}
		});
	},
	play: function() {
		$('.background .right video')[0].play();
		$('.background .left video')[0].play();
		
		this.playing = true;

		if (debug) {
			console.log("%cAmbient Video // Play", console_ambient_video);
		}
	},
	pause: function() {
		$('.background .right video')[0].pause();
		$('.background .left video')[0].pause();

		this.playing = false;

		if (debug) {
			console.log("%cAmbient Video // Pause", console_ambient_video);
		}
	},
	fade_in: function() {

		TweenMax.fromTo($('.background .cover'), 1.5, {
			autoAlpha: 1,
		}, {
			autoAlpha: 0,
			delay: .25
		});

		ambient_video.faded = true;

		/*$('#onthecourt .overflow_container').on('scroll', function () {
			if ($('#onthecourt .overflow_container').scrollTop() > (app.window_height * 1.1)) {
				if(ambient_video.playing) {
					//ambient_video.pause();
				}
			} else if(!ambient_video.playing) {
				//ambient_video.play();
			}
		});

		$('#offthecourt .overflow_container').on('scroll', function () {
			if ($('#offthecourt .overflow_container').scrollTop() > (app.window_height * 1.1)) {
				if(ambient_video.playing) {
					//ambient_video.pause();
				}
			} else if(!ambient_video.playing) {
				//ambient_video.play();
			}
		});*/

		if (debug) {
			console.log("%cAmbient Video // Fade In", console_ambient_video);
		}
	}
};


// Control all ScrollMagic Parallax Effects

var scroll_animation = {
	//off_scenes: [],
	//on_scenes: [],
	init: function() {
		if (parallax) {

			offController = new ScrollMagic.Controller({container: "#offthecourt_overflow_container"});
			onController = new ScrollMagic.Controller({container: "#onthecourt_overflow_container"});

			$("body").addClass("parallax");

			if (debug) {
				console.log("%cScroll Animation // Init", console_scroll_magic);
			}

			scroll_animation.add_scenes();

		} else {

			if (debug) {
				console.log("%cScroll Animation // Init - Disabled", console_scroll_magic);
			}

		}

	},
	add_scenes: function() {
		if (parallax) {
			scroll_animation.off_the_court();
			scroll_animation.on_the_court();

			if (debug) {
				console.log("%cScroll Animation // Add Scenes", console_scroll_magic);
			}
		}
	},
	off_the_court: function() {

		var i = 0;
		var timelines = [];
		var scenes = [];

		function randomIntFromInterval(min,max) {
			return Math.floor(Math.random()*(max-min+1)+min);
		}


		$('#offthecourt [data-scroller]').each(function() {

			if($(this).data('scroller')) {

				var scrollPercent = $(this).data('scroller');
				var height = $(this).outerHeight();
				var tranformY = scrollPercent * $(window).height();

				//var random = randomIntFromInterval(-500, 500);

				timelines[i] = new TimelineMax();
				timelines[i]

				.append([
					TweenMax.fromTo($(this), 1, {
						y: tranformY,
					}, {
						y: -1 * tranformY,
						ease: Linear.easeNone
					})
				]);

				$(this).wrap( "<div class='scroller-" + i + "'></div>" );

				scenes[i] = new ScrollMagic.Scene({
					triggerElement: ".scroller-" + i,
					duration: $(window).height() - (2*tranformY) + height,
					offset: tranformY
				})
				.setTween(timelines[i])
				.addTo(offController)
				.triggerHook(1);
			}

			i++;
		});
	},
	on_the_court: function() {

		var i = 0;
		var timelines = [];
		var scenes = [];

		function randomIntFromInterval(min,max) {
			return Math.floor(Math.random()*(max-min+1)+min);
		}


		$('#onthecourt [data-scroller]').each(function() {

			if($(this).data('scroller')) {

				var scrollPercent = $(this).data('scroller');
				var height = $(this).outerHeight();
				var tranformY = scrollPercent * $(window).height();

				//var random = randomIntFromInterval(-500, 500);

				timelines[i] = new TimelineMax();
				timelines[i]

				.append([
					TweenMax.fromTo($(this), 1, {
						y: tranformY,
					}, {
						y: -1 * tranformY,
						ease: Linear.easeNone
					})
				]);

				$(this).wrap( "<div class='scroller-" + i + "'></div>" );

				scenes[i] = new ScrollMagic.Scene({
					triggerElement: ".scroller-" + i,
					duration: $(window).height() - (2*tranformY) + height,
					offset: tranformY
				})
				.setTween(timelines[i])
				.addTo(onController)
				.triggerHook(1);
			}

			i++;
		});

		//console.log(scenes);
	},
	destroy: function() {
		if (debug) {
			console.log("%cScroll Animation // Destroy", console_scroll_magic);
		}
		if(controller){
			controller.destroy();
		}

		controller = null;
		intro_scene = null;
		off_the_court_scene = null;
		on_the_court_scene = null;
	},

	pause: function() {
		if (typeof offController !== 'undefined') {
			offController.enabled(false);
			//console.log("DISABLE OFF");
		}
		if (typeof onController !== 'undefined') {
			onController.enabled(false);
			//console.log("DISABLE ON");
		}
		if (debug) {
			//console.log("%cScroll Animation // Pause Tweens", console_scroll_magic);
		}
	},

	resume: function() {
		if (typeof offController !== 'undefined') {
			offController.enabled(true);
			//console.log("ENABLE OFF");
		}
		if (typeof onController !== 'undefined') {
			onController.enabled(true);
			//console.log("ENABLE ON");
		}
		if (debug) {
			//console.log("%cScroll Animation // Resume Tweens", console_scroll_magic);
		}
	}

};


// Expects input as 'nnnnnn' where each nn is a 
// 2 character hex number for an RGB color value
// e.g. #3f33c6
// Returns the average as a hex number without leading #
var averageRGB = (function () {

  // Keep helper stuff in closures
  var reSegment = /[\da-z]{2}/gi;

  // If speed matters, put these in for loop below
  function dec2hex(v) {return v.toString(16);}
  function hex2dec(v) {return parseInt(v,16);}

  return function (c1, c2) {

	// Split into parts
	var b1 = c1.match(reSegment);
	var b2 = c2.match(reSegment);
	var t, c = [];

	// Average each set of hex numbers going via dec
	// always rounds down
	for (var i=b1.length; i;) {
	  t = dec2hex( (hex2dec(b1[--i]) + hex2dec(b2[i])) >> 1 );

	  // Add leading zero if only one character
	  c[i] = t.length == 2? '' + t : '0' + t; 
	}
	return  c.join('');
  }
}());


function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}



if(gradient) {

	var colors = new Array(
		[62,35,255],
		[60,255,60],
		[255,35,98],
		[45,175,230],
		[255,0,255],
		[255,128,0]
	);

	var step = 0;

	var colorIndices = [0,1,2,3];

	var gradientSpeed = 0.002;

	function updateGradient() {

		if ( $===undefined ) return;

		var c0_0 = colors[colorIndices[0]];
		var c0_1 = colors[colorIndices[1]];
		var c1_0 = colors[colorIndices[2]];
		var c1_1 = colors[colorIndices[3]];

		var istep = 1 - step;
		var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
		var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
		var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
		var color1 = "rgb("+r1+","+g1+","+b1+")";
		var color1hex = rgbToHex(r1, g1, b1);

		var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
		var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
		var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
		var color2 = "rgb("+r2+","+g2+","+b2+")";
		var color2hex = rgbToHex(r2, g2, b2);

		$('.gradient').css({
			background : color1}).css({
			background : "-webkit-gradient(linear, left top, right top, color-stop(0%, "+color1+"), color-stop(100%, "+color2+"))"}).css({
			background : "-webkit-linear-gradient(left, "+color1+" 0%, "+color2+" 100%)"}).css({
			background : "-o-linear-gradient(left, "+color1+" 0%, "+color2+" 100%)"}).css({
			background : "-ms-linear-gradient(left, "+color1+" 0%, "+color2+" 100%)"}).css({
			background : "linear-gradient(to right, "+color1+" 0%, "+color2+" 100%)"}).css({
			filter : "progid:DXImageTransform.Microsoft.gradient( startColorstr='"+color1+"', endColorstr='"+color2+"',GradientType=0 )"
		});

		$('.gradient_left').css({
			backgroundColor: color1
		});

		$('.gradient_right').css({
			backgroundColor: color2
		});

		$('.menu-bar').css({
			backgroundColor: color2
		});

		$('.tyus_logo_link').css({
			color: color1
		});

		$('.tyus_logo').css({
			color: "#" + averageRGB(color1hex, color2hex),
		});

		$('.scroll_container .scroll .arrow > div').css({
			backgroundColor: "#" + averageRGB(color1hex, color2hex),
		});

		step += gradientSpeed;

		if ( step >= 1 ) {
			step %= 1;
			colorIndices[0] = colorIndices[1];
			colorIndices[2] = colorIndices[3];

			colorIndices[1] = ( colorIndices[1] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
			colorIndices[3] = ( colorIndices[3] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;

		}
	}

	setInterval(updateGradient,10);
}


function imageGallery() {

	var slider_highschool = new Swiper ('.highschool .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_college = new Swiper ('.college .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_pro_desktop = new Swiper ('.pro .hidden-sm-down .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_pro_mobile = new Swiper ('.pro .hidden-md-up .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_camps = new Swiper ('.camps .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_team1tyus = new Swiper ('.team1tyus .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

	var slider_lab = new Swiper ('.learninglab .swiper-container', {
		loop: true,
		autoplay: 4000,
		fade: {
			crossFade: true
		},
		pagination: '.swiper-pagination',
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
	});

}






// Listen for orientation changes
window.addEventListener("orientationchange", function() {

	setTimeout(app.clear_sizing, 50);
	setTimeout(app.size_content, 200);

}, false);


(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
	  var timeout;

	  return function debounced () {
		  var obj = this, args = arguments;
		  function delayed () {
			  if (!execAsap)
				  func.apply(obj, args);
			  timeout = null;
		  };

		  if (timeout)
			  clearTimeout(timeout);
		  else if (execAsap)
			  func.apply(obj, args);

		  timeout = setTimeout(delayed, threshold || 100);
	  };
  }
  // smartresize 
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');



// http://stackoverflow.com/questions/8950761/google-chart-redraw-scale-with-window-resize
$(window).smartresize(function () {
	if (app.page == "single-experience") {
		if (googlemaps.map.data) {
			if (!mobile) {
				googlemaps.createElevationChart();
			}
		}
	}
});




/**
 * Determine the currently active Bootstrap screen size. Used for running breakpoint dependant JS.
 */

/*!
 * Responsive Bootstrap Toolkit
 * Author:    Maciej Gurban
 * License:   MIT
 * Version:   2.2.0 (2015-01-06)
 * Origin:    https://github.com/maciej-gurban/responsive-bootstrap-toolkit
 */

var viewport = (function($){

	// Methods and properties
	var self = {

		// Determines interval between firing 'changed' method
		interval: 300,

		// Breakpoint aliases
		breakpoints: [
			'xs', 'sm', 'md', 'lg', 'xl'
		],

		// Used to calculate intervals between consecutive function executions
		timer: new Date(),

		// Returns true if current breakpoint matches passed alias
		is: function( alias ) {
			return $('.device-' + alias).is(':visible');
		},

		// Returns current breakpoint alias
		current: function(){
			var name = 'unrecognized';
			self.breakpoints.forEach(function(alias){
				if(self.is(alias)) {
					name = alias;
				}
			});
			return name;
		},

		/* 
		 * Waits specified number of miliseconds before executing a function
		 * Source: http://stackoverflow.com/a/4541963/2066118
		 */
		changed: function() {
			var timers = {};
			return function (callback, ms) {
				// Get unique timer ID
				var uID = (!uID) ? self.timer.getTime() : null;
				if (timers[uID]) {
					clearTimeout(timers[uID]);
				}
				// Use default interval if none specified
				if(typeof ms === "undefined") {
					var ms = self.interval;
				}
				timers[uID] = setTimeout(callback, ms);
			};
		}()

	}

	return self;

})(jQuery);


/**
 * Console log out the currently active Bootstrap screen size
 */

$(window).resize(function() {
	viewport.changed(function() {

		if (viewport.is('xs')) {
			if (debug) {
				console.log("%cScreen Size // XS", console_default);
			}
		}

		if (viewport.is('sm')) {
			if (debug) {
				console.log("%cScreen Size // SM", console_default);
			}
		}

		if (viewport.is('md')) {
			if (debug) {
				console.log("%cScreen Size // MD", console_default);
			}
		}

		if (viewport.is('lg')) {
			if (debug) {
				console.log("%cScreen Size // LG", console_default);
			}
		}

		if (viewport.is('xl')) {
			if (debug) {
				console.log("%cScreen Size // XL", console_default);
			}
		}

	})
});


/**
 * Fix random resize issues
 */

function doneResizing(){
	app.update();    
}


// http://stackoverflow.com/questions/4656843/jquery-get-querystring-from-url
// Read a page's GET URL variables and return them as an associative array.
function getUrlVars()
{
	var vars = [], hash;
	var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for(var i = 0; i < hashes.length; i++)
	{
		hash = hashes[i].split('=');
		vars.push(hash[0]);
		vars[hash[0]] = hash[1];
	}
	return vars;
}


//// INITIALIZE ////

$(document).ready(function() {
	app.init();
});

$(window).resize(function() {
	app.update();

	var resizeId;
	clearTimeout(resizeId);
	resizeId = setTimeout(doneResizing, 500);
});


